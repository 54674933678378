<template>
  <div class="select-comp">
    <span class="page u-regular-s">
      <span class="tt">
        研报页数
      </span>
      <el-select
        size="mini"
        v-model="pageValue"
        placeholder="请选择"
        @change="handleSelect"
      >
        <el-option
          v-for="item in pageOpts"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </span>
    <span class="time u-regular-s">时间
      <el-date-picker
        size="mini"
        v-model="timeValue"
        @change="handleSelect"
        value-format="yyyy-MM-dd"
        type="daterange"
        placeholder="选择日期"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
    </span>
  </div>
</template>

<script>
export default {
  data () {
    return {
      pageValue: ',',
      pageOpts: [
        {
          label: '不限',
          value: ',',
        },
        {
          label: '10页以下',
          value: '0,9',
        },
        {
          label: '10-20页',
          value: '10,20',
        },
        {
          label: '20页以上',
          value: '21,',
        },
      ],
      timeValue: '',
    }
  },
  components: {},
  computed: {},
  methods: {
    handleSelect() {
      const pageArr = this.pageValue.split(',')
      const timeArr = this.timeValue || []
      this.$emit('multi-select:confirm', {
        page_gte: pageArr[0] || null,
        page_lte: pageArr[1] || null,
        date_gte: timeArr[0] || null,
        date_lte: timeArr[1] || null,
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.select-comp {
  display: inline-block;
  .page {
    .tt {
      display: inline-block;
      width: 64px;
    }
  }
  .time {
    margin-left: 15px;
  }
  .el-select {
    width: 118px;
  }
  .el-date-editor {
    width: 240px;
    margin-left: 10px;
  }
}
</style>