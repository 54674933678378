<template>
  <div class="search-comp">
    <div class="search-wrap">
      <span class="u-max label">搜研报</span>
      <el-input
        placeholder="请输入研报相关内容，支持语义搜索"
        v-model="searchVal"
        class="input-with-btn"
        @keyup.enter.native="handleSearch"
      >
        <el-button
          slot="append"
          type="primary"
          plain
          icon="el-icon-search"
          @click="handleSearch"
        ></el-button>
      </el-input>
    </div>
    <div class="filter-wrap">
      <div class="filter-head">
        <HeadTitle text="查询条件"></HeadTitle>
        <el-button
          size="mini"
          plain
          class="btn"
          @click="emptyFilter"
        >
          清空所有条件
        </el-button>
      </div>
      <div
        :class="{'filter-cn g-ct-cn': true, 'filter-expand': isExpand}"
        :key="key"
      >
        <div class="filter-item">
          <FilterSelect @multi-select:confirm="selectFilterSearch"></FilterSelect>
        </div>
        <div class="filter-item">
          <MultiSelect
            @multi-select:confirm="multiFilterSearch"
            :configObj="{label: '行业', url: '/irapi/report/industry', keyName: 'industry_names'}"
          ></MultiSelect>
        </div>
        <div class="filter-item">
          <MultiSelect
            @multi-select:confirm="multiFilterSearch"
            :configObj="{label: '标签', url: '/irapi/report/label', keyName: 'report_labels'}"
          ></MultiSelect>
        </div>
        <div class="filter-item">
          <MultiSelect
            @multi-select:confirm="multiFilterSearch"
            :configObj="{label: '来源', url: '/irapi/report/source', keyName: 'report_sources', isGroup: true}"
          ></MultiSelect>
        </div>
        <div class="filter-item">
          <MultiSelect
            @multi-select:confirm="multiFilterSearch"
            :configObj="{label: '分类', url: '/irapi/report/category', keyName: 'category_names'}"
          ></MultiSelect>
        </div>
        <div class="expand-wrap">
          <span
            class="u-regular-s i-click"
            @click="isExpand = !isExpand"
          >
            {{isExpand ? '收起所有条件' : "展开所有条件"}}
            <i :class="isExpand ? 'el-icon-arrow-up ': 'el-icon-arrow-down'"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterSelect, MultiSelect } from './components/index'
import { HeadTitle } from '@/components/index'
export default {
  components: {
    FilterSelect,
    MultiSelect,
    HeadTitle
  },
  data () {
    return {
      searchOpts: [
        {value: 'picture', label: '图片'},
        {value: 'title', label: '标题', disabled: false, },
        {value: 'text', label: '全文', disabled: false, },
      ],
      searchVal: '',
      multiSearchObj: {},
      selectSearchObj: {},
      key: 0,
      isExpand: false,
    }
  },
  methods: {
    emptyFilter() {
      this.multiSearchObj = {}
      this.selectSearchObj = {}
      this.key++
      this.handleSearch()
    },
    handleSearch() {
      this.$emit('search-click', {
        // 先做成这个样子吧，乱七八糟
        search_query: this.searchVal,
        ...this.multiSearchObj,
        ...this.selectSearchObj
      })
    },
    selectFilterSearch(obj) {
      this.selectSearchObj = {
        ...this.selectSearchObj,
        ...obj
      }
      this.handleSearch()
    },
    multiFilterSearch({key, list}) {
      this.multiSearchObj = {
        ...this.multiSearchObj,
        [key]: list
      }
      this.handleSearch()
    }
  }
}
</script>
<style lang='scss' scoped>
.search-comp {
  .search-wrap {
    display: flex;
    width: 70%;
    margin: auto;
    align-items: center;
    .label {
      margin-right: 10px;
    }
    .input-with-btn {
      flex: 1;
      /deep/ .el-input-group__append,
      /deep/ .el-input__inner {
        border-right: none;
      }

      .el-button {
        height: 38px;
        width: 100px;
        color: #fff;
        background-color: var(--color-text-active);
        border-radius: var(--raduis-small);
      }
    }
  }
  .filter-wrap {
    position: relative;
    margin: 25px 0;
    .filter-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .filter-cn {
      height: 96px;
      padding-top: 15px;
      overflow: hidden;
      transition: height 0.5s ease-in-out;

      .filter-item {
        line-height: 47px;
        border-bottom: 1px solid var(--color-border-sec);
      }
      .expand-wrap {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0px;
        margin: auto;
        line-height: 48px;
        background: var(--color-bg-cn);
        text-align: center;
      }
    }
    .filter-expand {
      height: 293px;
    }
  }
}
</style>