<template>
  <div class="research-report-page">
    <SearchComp @search-click="searchAll"></SearchComp>
    <div class="res-wrap">
      <HeadTitle text="结果列表"></HeadTitle>
      <TabList
        :defaultActiveTab="activeTab"
        @changeActiveTab="changeActiveTab"
        :tabList="[{value: 'title', label: '标题' + renderCount('title')},{value: 'picture', label: '图片' + renderCount('picture')},{value: 'text', label: '全文' + renderCount('text')}]"
      ></TabList>
      <div v-loading="loading">
        <div v-if="list.length > 0">
          <ImageList
            :list="list"
            v-if="activeTab === 'picture'"
          ></ImageList>
          <ReportList
            :list="list"
            v-else
          ></ReportList>
        </div>
        <el-empty
          class="g-ct-cn"
          v-else
          :image-size="loading ? 0 : null"
          :description="loading? ' ' : '当前查询条件没有相关研报'"
        ></el-empty>
      </div>
    </div>
    <div class="page-wrap">
      <el-pagination
        v-if="!loading && list.length > 0"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.page"
        :page-size="pageInfo.limit"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { request } from '@/utils'
import { ImageList, ReportList, SearchComp } from './components/index'
import { HeadTitle, TabList } from '@/components/index'

export default {
  components: {
    ImageList,
    SearchComp,
    ReportList,
    HeadTitle,
    TabList
  },
  data () {
    return {
      searchObj: {},
      pageInfo: {
        page: 1,
        start: 0,
        limit: 12,
      },
      allLoading: true,
      activeTab: 'title',
      dataMap: {
        picture: {
          list: [],
          total: 0,
        },
        title: {
          list: [],
          total: 0,
        },
        text: {
          list: [],
          total: 0,
        },
      },
      loading: true
    }
  },
  computed: {
    list() {
      return this.dataMap[this.activeTab].list || []
    },
    total() {
      return this.dataMap[this.activeTab].count || 0
    }
  },
  mounted() {
    this.searchAll()
  },
  methods: {
    searchAll(params) {
      this.allLoading = true
      this.searchObj = params || this.searchObj
      this.changePageInfo(1)
      Promise.all([this.getList('picture'), this.getList('title'), this.getList('text')]).then((res) => {
        this.loading = false
        this.allLoading = false

        this.activeTab = (function getfirstNonEmptyTab (context) {
          let i = 0
          while (i<3) {
            const tab = ['title', 'picture', 'text'][i]
            if (context.dataMap[tab].count !== 0) {
              return tab
            }
            i++
          }
          return context.activeTab
        })(this)
      })
    },
    getList(searchType) {
      this.dataMap[searchType].list = []
      this.loading = true
      const searchKeyMap = {
        'picture': 'search',
        'title': 'search_title',
        'text': 'search_content',
      }
      const params = {
        ...this.searchObj,
        [searchKeyMap[searchType]]: this.searchObj.search_query,
        search_query: null,
      }
      const urlMap = {
        title: '/irapi/report',
        text: '/irapi/report',
        picture: '/irapi/picture',
      }
      return request({
        url: urlMap[searchType],
        method: 'get',
        params: {
          ...params,
          ...this.pageInfo,
        },
      }).then((res) => {
        const { data = {}} = res
        this.dataMap[searchType] = {
          list: data.datas,
          count: data.count
        }
      }).catch(() => {
        this.dataMap[searchType] = {
          list: [],
          count: 0
        }
      })
    },
    changePageInfo(page) {
      this.pageInfo.page = page
      this.pageInfo.start = (this.pageInfo.page - 1) * this.pageInfo.limit
    },
    handleCurrentChange(page) {
      this.changePageInfo(page)
      this.getList(this.activeTab).finally(() => {
        this.loading = false
      })
    },
    renderCount(searchType) {
      if (this.searchObj.search_query) {
        // 只有上面一次搜索多个，才会触发count的修改。点击tab切换，以及下面的页码切换时。都不会修改count
        if (this.allLoading) {
          return ''
        }
        const count = this.dataMap[searchType].count
        return `(${count})`
      }
      return ''
    },
    changeActiveTab(tabName) {
      this.activeTab = tabName
      this.changePageInfo(1)
    }
  }
}
</script>
<style lang='scss' scoped>
.research-report-page {
  margin: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .el-empty {
    height: 70vh;
    margin-bottom: 15px;
  }
  .page-wrap {
    text-align: right;
  }
}
</style>