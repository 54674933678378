<template>
  <div class="report-comp g-ct-cn i-link-cn">
    <div class="content">
      <div class="image-wrap">
        <el-image
          :class="{image: true, 'image-fail': !preview}"
          :src="`${fileInfo.json_path}/images/cover_1.png`"
          fit="cover"
          :preview-src-list="[`${fileInfo.json_path}/images/cover_1.png`]"
          @load="onLoad"
        >
          <div
            slot="error"
            class="error-tip"
          >
            暂无首页图
          </div>
        </el-image>
        <div
          class="preview"
          v-if="preview"
        >
          点击查看大图
        </div>
      </div>
      <PdfInfo
        :fileInfo="fileInfo"
        :showSummary="true"
        :clickTitle="clickReport"
      />
    </div>
  </div>
</template>

<script>
import { PdfInfo } from '@/components'
export default {
  components: {
    PdfInfo
  },
  props: {
    fileInfo: Object,
  },
  data () {
    return {
      preview: false,
    }
  },
  computed: {},
  methods: {
    clickReport() {
      const routeUrl = this.$router.resolve({
        path: '/pdf-img/view',
        query: {id: this.fileInfo.id}
      })
      window.open(routeUrl.href, '_blank')
    },
    onLoad() {
      this.preview = true
    }
  }
}
</script>
<style lang='scss' scoped>
.report-comp {
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;

  .content {
    display: flex;

    .image-wrap {
      position: relative;
      width: 220px;
      height: 123px;
      margin-right: 15px;
      overflow: hidden;
      flex: none;
      background: var(--color-bg-html);
      border-radius: var(--raduis-small);

      &:hover {
        .preview {
          color: #ffffff;
          font-size: 16px;
          background: rgba(0, 0, 0, 0.5);
        }
      }

      .image {
        position: initial;
        width: 100%;
        overflow: visible;
        /deep/ .el-image__inner {
          transform: scale(1.8);
          transform-origin: 0 0;
        }

        /deep/ .error-tip {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          color: #bdbdbd;
        }
      }

      .preview {
        cursor: zoom-in;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(1, 87, 155, 0.02);
        pointer-events: none;
        color: transparent;
        top: 0;
      }
    }
    .image-fail {
      height: 100%;
    }
  }
}
</style>