<template>
  <div class="multi-comp">
    <el-tooltip
      placement="bottom-start"
      effect="light"
      :manual="true"
      popper-class="opt-dropdown"
      :value="dropMenuShow"
    >
      <div slot="content">
        <OptList
          :opts="opts"
          :updateList="updateList"
          @handle-click="changeSelectList"
          :isGroup="configObj.isGroup"
        ></OptList>
      </div>
      <span
        :class="`drop-menu-tt i-click u-regular-s`"
        @click="dropMenuShow = !dropMenuShow"
      >
        {{configObj.label}}
        <i class="el-icon-arrow-down icon"></i>
      </span>
    </el-tooltip>
    <div class="selected-list u-regular-s">
      <span
        v-if="selectedList.length === 0"
        class="tip"
      >
        不限
      </span>
      <el-button
        plain
        v-else
        v-for="(item, index) in selectedList"
        :key="index"
        size="mini"
        @click="removeItem(item, index)"
      >{{item.name}}
        <i class="el-icon-circle-close"></i>
      </el-button>
    </div>
  </div>
</template>

<script>
import { request } from '@/utils'
import OptList from './opt-list.vue'
export default {
  components: {
    OptList
  },
  props: {
    configObj: Object
  },
  data () {
    return {
      opts: [],
      selectedList: [],
      updateList: [],
      dropMenuShow: false,
    }
  },
  computed: {},
  created() {
    this.getOpts()
  },
  mounted() {
    const bodyDOM = document.body
    bodyDOM.addEventListener('click', this.hideMenu)
  },
  destroyed() {
    const bodyDOM = document.body
    bodyDOM.removeEventListener('click', this.hideMenu)
  },
  methods: {
    hideMenu(evt) {
      const clickWrapDOM = evt.target.closest('.drop-menu-tt')
      const compDropDOM = this.$el.querySelector('.drop-menu-tt')
      /**
       * 点击的不是下拉框的label，而且当前弹框处于展示状态时，将其关闭
       * Notice：tooltip的点击事件在这里监听不到。因为监听的时候。tooltip还不在body的子元素中
       */
      if (compDropDOM !== clickWrapDOM && this.dropMenuShow) {
        this.dropMenuShow = false
      }
    },
    getOpts() {
      request({
        url: this.configObj.url,
        method: 'get',
      }).then((res) => {
        const { datas } = res.data
        if (Array.isArray(datas)) {
          this.opts = datas.map(item => {
            const { code} = item
            // 如果是分组数据，不对item进行处理
            if (this.configObj.isGroup) {
              return item
            } else {
              // 有的没有code 只有name
              return {
                ...item,
                code: code || item.name
              }
            }
          })
        }
      })
    },
    emitSearch() {
      this.$emit('multi-select:confirm', {
        key: this.configObj.keyName,
        list: this.selectedList.map(item => {
          return item.name
        })
      })
    },
    changeSelectList(list) {
      this.selectedList = list
      this.dropMenuShow = false
      this.emitSearch()
    },
    removeItem(item, index) {
      this.selectedList.splice(index, 1)
      this.updateList = this.selectedList
      this.emitSearch()
    }
  }
}
</script>
<style lang='scss' scoped>
.multi-comp {
  .drop-menu-tt {
    display: inline-block;
    width: 64px;
    .icon {
      padding-left: 4px;
    }
  }
  .selected-list {
    display: inline-block;
    .tip {
      line-height: 28px;
    }
    .el-button {
      line-height: 28px;
      height: 28px;
      padding: 0 10px;
      min-width: 118px;
      font-weight: normal;
      .el-icon-circle-close {
        font-size: 14px;
        padding-left: 5px;
      }
      /deep/ span {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
</style>
<style  lang='scss' >
.opt-dropdown {
  // border: 1px solid #e8e8e8 !important;
  border: 1px solid transparent !important;
  box-shadow: 0px 2px 14px 0px #00000019;

  /* box-shadow: -2px -2px 5px rgb(163 163 163 / 35%); */
  .popper__arrow {
    border-bottom-color: #e8e8e8 !important;
  }
}
</style>