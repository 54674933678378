<template>
  <div class="opts-show-all">
    <!-- <div class="search">
      <el-input
        placeholder="请输入内容"
        prefix-icon="el-icon-search"
      v-model="filterText"
      ></el-input>
    </div> -->
    <el-tabs
      class="group-cn"
      v-if="isGroup"
      v-model="curIndex"
    >
      <el-tab-pane
        class="name"
        v-for="(item, index) in opts"
        :key="item.pinyin_first_latter"
        :label="item.pinyin_first_latter"
        :name="String(index)"
      ></el-tab-pane>
    </el-tabs>
    <div>
      <el-button
        v-for="(item, index) in showList"
        :key="index"
        :class="{'btn-item': true, 'btn-high': !!selectedMap[item.code], 'f-ellipsis1': true}"
        size="mini"
        @click="handleClick(item)"
      >
        {{item.name}}
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    opts: Array,
    updateList: Array,
    isGroup: Boolean,
  },
  data () {
    return {
      selectedList: [],
      selectedMap: {},
      curIndex: '0',
    }
  },
  computed: {
    showList() {
      if (this.isGroup) {
        return (this.opts[this.curIndex] && this.opts[this.curIndex].items) || []
      } else {
        return this.opts
      }
    }
  },
  methods: {
    handleClick(item) {
      const _selected = !!this.selectedMap[item.code]
      this.$set(this.selectedMap, item.code, !_selected)
      if (_selected) {
        this.selectedList = this.selectedList.filter(selectedObj => {
          return selectedObj.code !== item.code
        })
      } else {
        this.selectedList.push(item)
      }
      this.$emit('handle-click', this.selectedList)
    }
  },
  watch: {
    updateList(list) {
      this.selectedList = list
      this.selectedMap = {}
      list.map(item => {
        this.selectedMap[item.code] = true
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.opts-show-all {
  width: 500px;
  background: #fff;
  line-height: 28px;
  .group-cn {
    /deep/ .el-tabs__item {
      padding: 0 7px !important;
      height: 28px;
      line-height: 28px;
    }
  }
  .btn-item {
    display: inline-block;
    line-height: 28px;
    width: 100%;
    margin: 2px;
    border: none;
    width: 90px;
    font-weight: normal;
    padding: 0;
  }
  .btn-high {
    color: var(--color-text-active);
    background: #ecf5ff;
  }
  /deep/ .el-button + .el-button {
    margin-left: 0;
  }
}
</style>