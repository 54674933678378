<template>
  <div class="">
    <PdfReport
      v-for="(item) in list"
      :key="item.id"
      :fileInfo="item"
    ></PdfReport>
  </div>
</template>

<script>
import PdfReport from './report'
export default {
  components: {
    PdfReport
  },
  props: {
    list: Array,
  },
  data () {
    return {

    }
  },
  computed: {},
  methods: {}
}
</script>
<style lang='scss' scoped>
</style>