<template>
  <div class="content-comp">
    <div class="img-wrap">
      <PdfImage
        v-for="(item) in list"
        :key="item.id"
        :imgData="item"
      ></PdfImage>
      <!-- i标签的作用是让最后一行的图片向左对齐 不可以去掉 -->
      <i></i><i></i><i></i>
    </div>
  </div>

</template>

<script>
import PdfImage from './image'
export default {
  components: {
    PdfImage
  },
  props: {
    list: Array,
  },
  data () {
    return {

    }
  },
  computed: {},
  methods: {}
}
</script>
<style lang='scss' scoped>
.content-comp {
  margin: 0 0 10px 0;
  .img-wrap {
    width: 100%;
    flex: 1;
    display: flex;
    align-content: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-right: -10px;
  }
}

@media (max-width: 1200px) {
  i {
    flex-basis: 32%;
  }
}
@media (min-width: 1201px) {
  i {
    flex-basis: 24%;
  }
}
</style>