<template>
  <div
    class="g-ct-cn i-link-cn img-all-comp"
    @click="clickImg"
  >
    <div class="img-wrap">
      <img
        :src="imgData.file_path"
        class="image"
      >
    </div>
    <div class="text-wrap">
      <a class="tt-wrap">
        <span class="u-title i-link f-ellipsis2"> {{imgData.name}}</span>
        <el-tooltip
          popper-class="detail-more"
          effect="light"
          placement="right-start"
        >
          <div slot="content">
            <div class="more-info">
              <div class="u-title-s">
                {{imgData.name}}
              </div>
              <el-divider
                content-position="center"
                class="divider-info"
              >PDF详情</el-divider>
              <div
                class="item"
                v-for="item in [{key: 'title', name: '标题'},{key: 'report_source', name: '来源'}, {key: 'author', name: '作者'},{key: 'report_label', name: '标签'}, {key: 'category_name', name: '分类'}, {key: 'file_page', name: '页码'}]"
                :key="item.key"
              >
                <span v-if="imgData.report[item.key]">
                  <span class="u-sec">{{item.name}}：</span>
                  <span class="u-regular-s"> {{imgData.report[item.key]}}</span>
                </span>
              </div>
            </div>
          </div>
          <i class="el-icon-info"></i>
        </el-tooltip>
      </a>
      <div class="u-regular tip">
        <span>
          {{imgData.report.report_source}}
        </span>
        <span>{{imgData.report.publish_dt}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imgData: Object,
  },
  data () {
    return {
    }
  },
  methods: {
    clickImg() {
      const routeUrl = this.$router.resolve({
        path: '/pdf-img/view',
        query: {id: this.imgData.report_id, imgId: this.imgData.id, page: this.imgData.pdf_page, location: this.imgData.pdf_location}
      })
      window.open(routeUrl.href, '_blank')
    }
  }
}
</script>
<style lang='scss' scoped>
@import '../../../../../assets/styles/common.scss';

.img-all-comp {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 312px;
  margin-bottom: 20px;
  padding: 0;
  overflow: hidden;

  .img-wrap {
    overflow: hidden;
    width: 100%;
    height: 200px;
    min-height: 200px;
    border-bottom: 1px solid var(--color-border-regular);
    text-align: center;
    .image {
      height: 100%;
      width: 85%;
      object-fit: contain;
      transition: 0.5s;
    }
    &:last-child {
      margin-right: auto;
    }
  }
  .text-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 15px 20px 20px 20px;
    .tt-wrap {
      display: flex;

      .el-icon-info {
        font-size: 16px;
        color: #c0c4cd;
        padding: 5px 0 0 5px;
        &:hover {
          color: var(--color-text-active);
        }
      }
    }
    .tip {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }
  }
}
.more-info {
  max-width: 240px;
  padding: 5px 10px;
  .divider-info {
    margin: 15px 0;
    .el-divider__text {
      @extend .u-sec;
      font-weight: normal;
    }
  }
  .item {
    line-height: 20px;
    .label {
      color: rgb(144, 147, 153);
      color: #303133;
    }
    .value {
      color: rgb(96, 98, 102);
      color: #909399;
    }
  }
}

@media (max-width: 1700px) {
  .img-all-comp {
    flex-basis: 32%;
  }
}
@media (min-width: 1441px) {
  .img-all-comp {
    flex-basis: 23.5%;
  }
}
</style>
<style lang='scss' >
.detail-more {
  // border: 1px solid #eee !important;
  // box-shadow: 0px 2px 14px 0px #00000019;
  border: 1px solid transparent !important;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  /* */
  .popper__arrow {
    border-right-color: #eee !important;
    border-left-color: #eee !important;
    // border-bottom-color: #e8e8e8 !important;
  }
}
</style>